<template>
    <v-container fluid class="pa-6">
        <v-row>
            <v-col class="docWidth">
                <v-card rounded="lg" class="px-7 pt-5 pb-1">
                    <h3>API de Lexxy</h3>
                    <p class="mt-3">
                        Avec Lexxy API, créez des applications personnalisées totalement adaptées à vos besoins. 
                        Travaillez avec tous les éléments basiques de Lexxy (Contacts, Demandes, Rendez-vous...) avec des moyens de création, annulation et d'édition. 
                        Vous trouverez la liste complète des possibilités et des contraintes dans la documentation ci-dessous.
                    </p>
                    <p class="mt-3">
                        Serveur : <strong>{{ api }}apipro/{{ "\{\{token\}\}" }}</strong>
                    </p>
                </v-card>
                <v-card rounded="lg" class="pa-4 mt-4">
                    <div>
                        <div id="swagger-ui"></div>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script src="/assets/scripts/swagger/swagger-ui-bundle.js" charset="UTF-8"> </script>
<script src="/assets/scripts/swagger/swagger-ui-standalone-preset.js" charset="UTF-8"> </script>

<script>
import GenericDataService from '../../../services/GenericDataService';

export default {
    name: 'ApiDocList',
    data() {
        return {
            api: this.$store.state.auth.apiUrl,
        }
    },
    mounted() {
        GenericDataService.getData('/externalapi/getDocumentation').then((response) => {
            window.SwaggerUIBundle({
                dom_id: '#swagger-ui',
                spec: response.data.data,
                supportedSubmitMethods: [] // Désactiver les boutons "Try it out"
            });
            setTimeout(() => { this.addCopyToClipboardEvent(); }, 500);
        });
    },
    methods: {
        addCopyToClipboardEvent() {
            const copyButtons = document.querySelectorAll('div[title="Copy to clipboard"]');
            copyButtons.forEach(button => {
                button.addEventListener('click', this.copyToClipboard);
            });
        },
        copyToClipboard(event) {
            const button = event.currentTarget;
            const nextButton = button.nextElementSibling; // Sélectionner le bouton suivant
            const ariaLabel = nextButton.getAttribute('aria-label'); // Récupérer le texte de l'attribut aria-label
            const parts = ariaLabel.split(' '); // Diviser le texte sur les espaces
            const textToCopy = parts[1]; // Prendre la deuxième valeur
            navigator.clipboard.writeText(textToCopy);
        }
    }
}
</script>

<style lang="scss">
    #swagger-ui {
        .wrapper {
            padding: 0;
        }
        .swagger-ui button {
            padding-block: 1px;
            padding-inline: 6px;
        }
        .information-container, .scheme-container {
            display: none;
        }
        h4 {
            padding-bottom: 0;
            text-transform: inherit;
            border-bottom: none;
        }
    }

    .docWidth {
        max-width: 1500px;
        margin: auto;
    }
</style>